import classNames from 'classnames';
import { bool, func, instanceOf, number, object, oneOfType, shape, string } from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  AvatarMedium,
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  ShippingOptions,
  ListingQualityAssurance,
  PayPalButton,
  InlineTextButton,
  PrimaryButton,
  ReferralComponent,
  IconPaypalPurchase,
} from '../../components';
import config from '../../config';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import WhatsAppChat from '../../components/WhatsAppChat/WhatsAppChat.js';
import {
  handleCardPayment,
  retrievePaymentIntent,
  handleCardSetup,
  createStripeSetupIntent,
} from '../../ducks/stripe.duck';
import { StripePaymentForm, PaypalPaymentForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
} from '../../util/data';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPaymentPendingQuickPurchase,
  txIsRequested,
} from '../../util/transaction';
import { DATE_TYPE_DATE, LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './CheckoutPage.css';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  confirmPayment,
  initiateOrder,
  sendMessage,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  queryUserTransactions,
  addAbandonedShoppingCart,
  updateSpeculateTransaction,
  createShipmentQuotation,
  sendEnquiry,
  messageTransactionValidation,
  tokenizeCard,
  createClient,
  getClient,
  createOrder,
  captureOrder,
  createPaymentMethod,
  updateProfile,
  updateTransaction,
  createEmptyToken,
  validatePaypalAmount,
  updateAddress,
  updatePhoneNumber,
  createPaypalOrder,
  approvePaypalOrder,
  checkAndValidateReferralCode,
  updateUserPrivateData,
} from './CheckoutPage.duck';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { getIsZipCodeAvailable } from '../../ducks/Address.duck';

import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';

import SectionPhoneNumber from './SectionPhoneNumber';

import SectionAddress from './SectionAddress';
import { getAmountAmplitude, logEvent, logPixelFacebook } from '../../util/logsEvent';

const { UUID } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);
  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction) ||
      txIsPaymentPendingQuickPurchase(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

const getIsOffer = speculatedTransactionMaybe => {
  return speculatedTransactionMaybe
    ? speculatedTransactionMaybe.attributes.lineItems[0].quantity.d[0] !== 1
    : false;
};
export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      shippingPrice: 109,
      originalShippingPrice: 0,
      isFirstBuy: false,
      shippingMethod: 'normalDelivery',
      isEnquiryModalOpen: false,
      paymentType: 'direct-payment',
      conektaCustomer: null,
      isShippingOptionBeingChanged: false,
      isListingQAOptionsBeingChanged: false,
      isMsiOptionsBeingChanged: false,
      msi: null,
      hasShippingMethodBeingChanged: false,
      hasQualityAssuranceMethodChange: false,
      isAddressModalOpen: false,
      isPhoneNumberModalOpen: false,
      submitDisabledStripe: false,
      shouldHideComponents: false,
      zipIsUnavailable: false,
      address: null,
      phoneNumber: null,
      shouldFetchSpeculatedTransaction: false,

      referredCode: null,
      showTooltip: false,
      tooltipX: 0,
      tooltipY: 0,

      hasAddressBeingTouched: false,
      hasPhoneBeingTouched: false,
      isPhoneValid: true,

      referredCodeValidation: '',

      paypalTransactionState: null,

      chosenMsiInstallments: null,

      isNewAddressCompleted: false,

      fieldValidationStatus: false,

      ListingQualityAssurance: false,

      ListingQualityAssurance: false,

      isInitialDataBeingLoaded: true,

      updateError: false,

      isFacturationAddressNeeded: false,

      showWhatsAppChat: false,

      stripeState: {
        error: null,
        cardValueValid: false,
      },


      isExpressDeliveryChosen: false
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.msiIntallmentsChange = this.msiIntallmentsChange.bind(this);
    this.handleChildSubmit = this.handleChildSubmit.bind(this);
    this.handleSubmitDisabledState = this.handleSubmitDisabledState.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.handleWhatsAppClose = this.handleWhatsAppClose.bind(this);

    

    this.onSubmitPhoneNumber = this.onSubmitPhoneNumber.bind(this);
    this.onSubmitAddress = this.onSubmitAddress.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOfferSubmit = this.handleOfferSubmit.bind(this);
    this.handlePaypalInit = this.handlePaypalInit.bind(this);
    this.handlePaypalPurchase = this.handlePaypalPurchase.bind(this);

    this.onShippingPriceChange = this.onShippingPriceChange.bind(this);
    this.onNewShippingMethodSelection = this.onNewShippingMethodSelection.bind(this);

    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.setIsEnquiryModalOpen = this.setIsEnquiryModalOpen.bind(this);

    this.onListingQualityAssuranceChange = this.onListingQualityAssuranceChange.bind(this);

    this.handleValueChange = this.handleValueChange.bind(this);

    this.stripeComponentRef = createRef(null);
    this.setStripeState = this.setStripeState.bind(this);
  }

  componentDidMount() {
    if (window && this.props.currentUser && this.props.listing) {
      logEvent('checkout started');
      this.loadInitialData();
    }

    const hideWhatsAppChat = localStorage.getItem('hideWhatsAppChat');
    if (!hideWhatsAppChat || hideWhatsAppChat === 'false') {
      this.setState({ showWhatsAppChat: true });
    } else {
      this.setState({ showWhatsAppChat: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      shippingPrice,
      ListingQualityAssurance,
      isInitialDataBeingLoaded,
      dataLoaded,
    } = this.state;
    const { userReferralCode } = this.props;
    // Checking for currentUser update
    if (!prevProps.currentUser && this.props.currentUser && this.props.listing && !this.state.initialDataLoaded) {
      this.loadInitialData();
      this.setState({ initialDataLoaded: true });
    }

    // Existing logic for updating the transaction

    if (
      (prevState.shippingPrice !== shippingPrice ||
        prevState.ListingQualityAssurance !== ListingQualityAssurance ||
        prevProps.userReferralCode !== userReferralCode) &&
      dataLoaded
    ) {
      this.updateTransaction();
    }
  }

  handleWhatsAppClose(){
    localStorage.setItem('hideWhatsAppChat', 'true');
    this.setState({showWhatsAppChat: false})
  }

  onContactUser() {
    const { history, params, onMessageTransactionValidation, currentUser, listing } = this.props;

    const routes = routeConfiguration();
    const listingId = listing.id;
    const receiver = listing.author.id.uuid;

    const sender = currentUser.id.uuid;
    //send listing so that onEnquiry we can validate that there is a message with this listingId
    // let enquiryParamas = {listing}

    onMessageTransactionValidation(listingId.uuid, receiver, sender)
      .then(txId => {
        if (txId !== null) {
          history.push(
            createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
          );
        } else {
          this.setState({ isEnquiryModalOpen: true });
        }
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  async loadInitialData() {
    const {
      currentUser,
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      userTransactions,
      onAddAbandonedShoppingCart,
    } = this.props;
    if (bookingDates === null) {
      window.location.href = window.location.pathname.replace('/checkout', '');
    }
    const isPrimeMember = currentUser?.attributes?.profile?.privateData?.isPrimeMember;

    const currentTimestamp = Math.floor(Date.now() / 1000);

    const isPrimeMemberExpired = isPrimeMember ? currentUser?.attributes?.profile?.privateData?.primeMemberSubscriptionEndDate ? currentUser?.attributes?.profile?.privateData?.primeMemberSubscriptionEndDate < currentTimestamp : true : true;

    this.setState({
      ListingQualityAssurance:
        listing.attributes.price.amount * bookingDates.customPrice >= 1000000,
        isExpressDeliveryChosen: isPrimeMember && !isPrimeMemberExpired && listing.attributes.price.amount * bookingDates.customPrice >= 50000,
        shippingMethod: isPrimeMember && !isPrimeMemberExpired ? 'expressDelivery' : 'normalDelivery'
    });
    const freeShippingVal = currentUser ? await userTransactions(currentUser.id.uuid) : null;

    const hasreferredCode = currentUser?.attributes?.profile?.privateData?.referredCode
      ? true
      : false;

    if (hasreferredCode) {
      this.setState({ referredCode: currentUser.attributes.profile.privateData.referredCode });
    }

    if (this.props.bookingDates?.shippingPrice > 170 && freeShippingVal?.firstBuy) {
      this.state.shippingPrice = this.props.bookingDates.shippingPrice;
      this.setState({ isFirstBuy: true });
      this.state.isFirstBuy = true;
    } else if (freeShippingVal?.firstBuy) {
      this.setState({ isFirstBuy: true });
      this.state.isFirstBuy = true;
    }

    this.state.shippingPrice = this.props.bookingDates.shippingPrice;

    this.state.originalShippingPrice = this.props.bookingDates.shippingPrice;

    this.setState({ originalShippingPrice: this.props.bookingDates.shippingPrice });
    const addCart = listing
      ? onAddAbandonedShoppingCart(currentUser.id.uuid, listing.id.uuid)
      : null;
    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      !isBookingCreated;

    this.setState({ shouldFetchSpeculatedTransaction });
    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;
      const { bookingStart, bookingEnd } = pageData.bookingDates;

      let isOfferTransaction = pageData.bookingDates.customPrice !== 1;

      //

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      fetchSpeculatedTransaction(
        {
          listingId,
          bookingStart: bookingStartForAPI,
          bookingEnd: bookingEndForAPI,
          customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
          shippingPrice: this.state.originalShippingPrice,
          originalShippingPrice: this.state.originalShippingPrice,
          shippingMethod: this.state.shippingMethod,
          isFirstBuy: this.state.isFirstBuy,
          currentUserId: this.props.currentUser.id.uuid,
          ListingQualityAssurance: this.state.ListingQualityAssurance,
        },
        transactionId,
        isOfferTransaction,
        'stripe'
      );
    }

    const requiredAddressKeys = ['name', 'address_line', 'ext_number', 'neighborhood', 'zip', 'state', 'city'];
    const address = currentUser.attributes.profile.privateData.address || {};
    
    const isAddressComplete = requiredAddressKeys.every(key => address?.[key]);
    const isAddressModalOpen = !isAddressComplete;
    
    this.setState({
      address: currentUser.attributes.profile.privateData.address,
      isAddressModalOpen: isAddressModalOpen,
      stripeState: { cardValueValid: !!currentUser.stripeCustomer },
    });

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  async onShippingPriceChange(option, shippingPrice) {
    this.setState({ isShippingOptionBeingChanged: true });
    const shippingChosenShippingPrice =
      option === 'normalDelivery' ? this.state.originalShippingPrice : shippingPrice;
    this.setState({
      shippingPrice: shippingChosenShippingPrice,
      isShippingOptionBeingChanged: false,
      hasShippingMethodBeingChanged: true,
    });
  }

  async onListingQualityAssuranceChange() {
    this.setState({ ListingQualityAssurance: !this.state.ListingQualityAssurance });
  }

  onNewShippingMethodSelection(shippingMethod) {
    this.setState({ shippingMethod });
  }

  updateTransaction() {
    const { pageData, shippingPrice, isFirstBuy, ListingQualityAssurance } = this.state;
    const listingId = pageData.listing.id;
    this.props.onUpdateSpeculateTransaction({
      listingId,
      customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
      shippingPrice,
      isFirstBuy,
      originalShippingPrice: this.state.originalShippingPrice,
      currentUserId: this.props.currentUser.id.uuid,
      referredCode: this.props.userReferralCode ? this.props.userReferralCode : null,
      msiInstallments: this.state.chosenMsiInstallments,
      ListingQualityAssurance: ListingQualityAssurance,
      shippingMethod: this.state.shippingMethod,
    });
  }

  msiIntallmentsChange(msiInstallments) {
    this.setState({ chosenMsiInstallments: msiInstallments.value, isMsiOptionsBeingChanged: true });
    const { pageData, shippingPrice, isFirstBuy, ListingQualityAssurance } = this.state;
    const listingId = pageData.listing.id;
    this.props
      .onUpdateSpeculateTransaction({
        listingId,
        customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
        shippingPrice,
        isFirstBuy,
        originalShippingPrice: this.state.originalShippingPrice,
        currentUserId: this.props.currentUser.id.uuid,
        msiInstallments: msiInstallments.value,
        referredCode: this.props.userReferralCode ? this.props.userReferralCode : null,
        ListingQualityAssurance: ListingQualityAssurance,
        shippingMethod: this.state.shippingMethod,
      })
      .then(() => {
        this.setState({ isMsiOptionsBeingChanged: false });
      })
      .catch(() => {
        this.setState({ isMsiOptionsBeingChanged: false });
      });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      bookingDates,
      onCreateSetupIntent,
      onUpdateUserPrivateData,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      card,
    } = handlePaymentParams;

    let lastTransition = speculatedTransaction?.attributes?.lastTransition;
    const storedTx = ensureTransaction(pageData.transaction);

    let isManualPayment =
      pageData?.transaction?.attributes?.lastTransition ===
      'transition/transition-to-manual-payment'
        ? true
        : false;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    //

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      let isOfferInit = this.props.bookingDates.customPrice !== 1;
      fnParams.shippingPrice = this.state.shippingPrice;
      fnParams.originalShippingPrice = this.state.originalShippingPrice;
      fnParams.shippingMethod = this.state.shippingMethod;
      fnParams.isManualPayment = isManualPayment;
      fnParams.isFirstBuy = this.state.isFirstBuy;
      fnParams.referredCode = this.state.referredCode;
      fnParams.currentUserId = this.props.currentUser.id.uuid;
      fnParams.msiInstallments = this.state.chosenMsiInstallments;
      fnParams.ListingQualityAssurance = this.state.ListingQualityAssurance;
      fnParams.shippingMethod = this.state.shippingMethod;
      fnParams.phoneNumber = this.state.hasPhoneBeingTouched ? this.state.phoneNumber : null;

      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(fnParams, null, isOfferInit);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = async fnParams => {
      // <-- Added async keyword here
      // fnParams should be returned transaction entity
      const order = ensureTransaction(fnParams);

      if (order.id) {
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;

      let cardTokenResult = {};

      if (selectedPaymentFlow !== USE_SAVED_CARD) {
        const address = this.state.address;
        let cardBillingDetails = this.state.isFacturationAddressNeeded
          ? {
              name: billingDetails.name,
              address_line1: `${address.address_line} ${address.ext_number} ${address.neighborhood}`,
              address_city: address.city,
              address_state: address.state,
              address_zip: address.zip,
              address_country: 'MX',
            }
          : {
              name: billingDetails.name,
              address_line1: billingDetails.address.line1,
              address_city: billingDetails.address.city,
              address_state: billingDetails.address.state,
              address_zip: billingDetails.address.postal_code,
              address_country: billingDetails.address.country,
            };
        cardTokenResult = await stripe.createToken(card, cardBillingDetails);
      }

      let cardToken;
      if (selectedPaymentFlow !== USE_SAVED_CARD) {
        cardToken = cardTokenResult.token;
      }

      const stripeElementMaybe =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? { tokenId: cardToken.id, cardToken: cardToken.card.id }
          : {};

      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        orderId: order.id,
        order: order,
        ...stripeElementMaybe,
        paymentParams,
        selectedPaymentFlow,
        stripePaymentMethodId: this.props.currentUser?.stripeCustomer?.defaultPaymentMethod
          ?.attributes?.stripePaymentMethodId,
        stripeCustomer: this.props.currentUser?.stripeCustomer?.attributes?.stripeCustomerId,
        stripeCustomerId: this.props.currentUser?.stripeCustomer?.attributes?.stripeCustomerId,
        currentUserId: this.props.currentUser.id.uuid,
        msiInstallments: this.state.chosenMsiInstallments,
        ListingQualityAssurance: this.state.ListingQualityAssurance,
        shippingMethod: this.state.shippingMethod,
        listingName: this.props.listing.attributes.title,
        metadata: {
          transactionId: order.id.uuid,
          customerId: this.props.currentUser.id.uuid,
          providerId: fnParams.provider.id.uuid,
        },
        ...billingDetails,
      };

      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onHandleCardPayment(params);
    };

    //handles card setup in case of it being an offer with new card (to validate card and save or create stripe user if needed)
    const fnHandlePaymentSetup = async fnParams => {
      // <-- Added async keyword here
      // fnParams should be returned transaction entity
      const order = ensureTransaction(fnParams);

      if (order.id) {
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;

      let cardTokenResult = {};

      if (selectedPaymentFlow !== USE_SAVED_CARD) {
        const address = this.state.address;
        let cardBillingDetails = this.state.isFacturationAddressNeeded
          ? {
              name: billingDetails.name,
              address_line1: `${address.address_line} ${address.ext_number} ${address.neighborhood}`,
              address_city: address.city,
              address_state: address.state,
              address_zip: address.zip,
              address_country: 'MX',
            }
          : {
              name: billingDetails.name,
              address_line1: billingDetails.address.line1,
              address_city: billingDetails.address.city,
              address_state: billingDetails.address.state,
              address_zip: billingDetails.address.postal_code,
              address_country: billingDetails.address.country,
            };
        cardTokenResult = await stripe.createToken(card, cardBillingDetails);
      }

      let cardToken;
      if (selectedPaymentFlow !== USE_SAVED_CARD) {
        cardToken = cardTokenResult.token;
      }

      const stripeElementMaybe =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? { tokenId: cardToken.id, cardToken: cardToken.card.id }
          : {};

      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        orderId: order.id,
        order: order,
        ...stripeElementMaybe,
        paymentParams,
        selectedPaymentFlow,
        stripePaymentMethodId: this.props.currentUser?.stripeCustomer?.defaultPaymentMethod
          ?.attributes?.stripePaymentMethodId,
        stripeCustomerId: this.props.currentUser?.stripeCustomer?.attributes?.stripeCustomerId,
        currentUserId: this.props.currentUser.id.uuid,
        msiInstallments: this.state.chosenMsiInstallments,
        ListingQualityAssurance: this.state.ListingQualityAssurance,
        shippingMethod: this.state.shippingMethod,
        ...billingDetails,
      };

      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return selectedPaymentFlow === USE_SAVED_CARD
        ? // ? Promise.resolve({ orderId: order.id, paymentIntent, ...fnParams })
          onUpdateUserPrivateData(
            this.props.currentUser.id.uuid,
            this.props.currentUser?.stripeCustomer?.attributes?.stripeCustomerId
          ).then(() => {
            return { orderId: order.id, paymentIntent, ...fnParams };
          })
        : onCreateSetupIntent(params).then(res => {
            return { ...res, ...fnParams };
          });
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      if (!fnParams) {
        return;
      }

      createdPaymentIntent = fnParams.paymentIntent;
      let isOffer = bookingDates.customPrice !== 1;

      //

      return onConfirmPayment(fnParams, isOffer);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;
      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE && pi) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };
    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation =
      lastTransition === 'transition/send-offer' ||
      lastTransition === 'transition/enquiry-send-offer'
        ? composeAsync(fnHandlePaymentSetup, fnRequestPayment, fnSendMessage)
        : composeAsync(
            fnRequestPayment,
            fnHandleCardPayment,
            fnConfirmPayment,
            fnSendMessage,
            fnSavePaymentMethod
          );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
      // bookingStart: new Date(),
      // bookingEnd: tomorrow,
      ...optionalPaymentParams,
    };
    return handlePaymentIntentCreation(orderParams).catch(error => {
      // Handle or log the error
      throw error; // Re-throw to ensure it propagates if needed
    });
  }

  initiatePaypalOrder() {
    const { onInitiateOrder, listing, speculatedTransaction } = this.props;

    const pageData = this.state.pageData;

    const storedTx = ensureTransaction(pageData.transaction);

    const currentListing = ensureListing(listing);

    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      fnParams.shippingPrice = this.state.shippingPrice;
      fnParams.originalShippingPrice = this.state.originalShippingPrice;
      fnParams.shippingMethod = this.state.shippingMethod;
      fnParams.isManualPayment = false;
      fnParams.isFirstBuy = this.state.isFirstBuy;
      fnParams.referredCode = this.state.referredCode;
      fnParams.ListingQualityAssurance = this.state.ListingQualityAssurance;
      fnParams.shippingMethod = this.state.shippingMethod;
      fnParams.phoneNumber = this.state.hasPhoneBeingTouched ? this.state.phoneNumber : null;
      fnParams.metadata = {
        shippingMethod: this.state.shippingMethod,
        paymentMethod: 'paypal',
      };
      fnParams.currentUserId = this.props.currentUser.id.uuid;

      //
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(fnParams, null, false, 'paypal').then(res => {
            return { ...res };
          });
    };

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(fnRequestPayment);

    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
      listingTitle: currentListing.title,
    };
    return handlePaymentIntentCreation(orderParams).catch(error => {
      // Handle or log the error
      throw error; // Re-throw to ensure it propagates if needed
    });
  }

  transitionPaypalOrder(order_id) {
    const { onInitiateOrder, listing } = this.props;

    const pageData = this.state.pageData;
    const currentListing = ensureListing(listing);

    const fnTransitionPaypal = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      return onInitiateOrder(fnParams, this.state.paypalTransactionState.id, false, 'paypal').then(
        res => {
          return { ...res, order_id: fnParams.order_id };
        }
      );
    };

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaypalTransition = composeAsync(fnTransitionPaypal);

    const tx = this.state.paypalTransactionState;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      customPrice: pageData.bookingDates.customPrice ? pageData.bookingDates.customPrice : 1,
      listingTitle: currentListing.title,
      order_id: order_id,
    };
    return handlePaypalTransition(orderParams);
  }

  handleChildSubmit() {
    logEvent('order submitted', {
      ['total cart value']: getAmountAmplitude(this.props.listing.attributes.price.amount),
      ['total shipping']: this.state.shippingPrice,
      ['shipping method']: this.state.shippingMethod === 'normalDelivery' ? 'standard' : 'express',
      ['payment method']:
        this.state.paymentType === 'direct-payment' ? 'stripe' : this.state.paymentType,
      ['discount code']: this.state.isFirstBuy ? 'first buy' : '',
      ['total discounts']: this.state.isFirstBuy ? 109 : 0,
      ['order type']: getIsOffer(this.props.speculatedTransaction) ? 'offer' : 'purchase',
    });

    this.stripeComponentRef.current.finalFormAPI.submit();
  }

  changePaymentMethod(changedTo) {
    this.setState({ shouldHideComponents: changedTo === 'replaceCard' });
  }

  handleSubmitDisabledState(newValue, paymentMethod) {
    this.setState({ submitDisabledStripe: newValue });
  }

  handleValueChange(newValue, scope) {
    // this.setState({ value: newValue }); // Update the variable in the parent component
  }

  setStripeState(stripeState) {
    this.setState({ stripeState });
  }

  getClientSecret(setupIntent) {
    return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
  }

  getPaymentParams(currentUser, formValues) {
    const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const paymentParams = {
      payment_method_data: {
        billing_details: billingDetails,
      },
    };

    return paymentParams;
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }

    this.setState({ submitting: true });
    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      onUpdateAddress,
    } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    // First, check if address needs to be updated
    const updateAddressIfNeeded = this.state.hasAddressBeingTouched
      ? onUpdateAddress(currentUser.id.uuid, { address: this.state.address })
      : Promise.resolve();

    updateAddressIfNeeded
      .then(() => {
        // After address update or if no update needed, proceed with payment
        const requestPaymentParams = {
          pageData: this.state.pageData,
          speculatedTransaction,
          stripe: this.stripe,
          card,
          billingDetails,
          message,
          paymentIntent,
          selectedPaymentMethod: paymentMethod,
          saveAfterOnetimePayment: !!saveAfterOnetimePayment,
        };

        this.handlePaymentIntent(requestPaymentParams)
          .then(res => {
            const { orderId, messageSuccess, paymentMethodSaved } = res;
            this.setState({ submitting: false });
            const routes = routeConfiguration();
            const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
            const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
              id: orderId.uuid,
            });
            const initialValues = {
              initialMessageFailedToTransaction,
              savePaymentMethodFailed: !paymentMethodSaved,
            };
            initializeOrderPage(initialValues, routes, dispatch);
            clearData(STORAGE_KEY);


            if(formValues.addressLine1){
              logEvent('invoice info filled', { 
                address: formValues.addressLine1, 
                city: formValues.city, 
                zipcode:formValues.postal,
                ['cardholder name']:formValues.name,
                ['internal number']:formValues.addressLine2,
                state: formValues.state
                });
             }

            logEvent('order completed', {
              ['total cart value']: getAmountAmplitude(this.props.listing.attributes.price.amount),
              ['total shipping']: this.state.shippingPrice,
              ['shipping method']:
                this.state.shippingMethod === 'normalDelivery' ? 'standard' : 'express',
              ['payment method']:
                this.state.paymentType === 'direct-payment' ? 'stripe' : this.state.paymentType,
              ['order id']: orderId.uuid,
              ['discount code']: this.state.isFirstBuy ? 'first buy' : '',
              ['total discounts']: this.state.isFirstBuy ? 109 : 0,
              ['order type']: getIsOffer(this.props.speculatedTransaction) ? 'offer' : 'purchase',
            });
            logPixelFacebook('Purchase', {
              values: getAmountAmplitude(this.props.listing.attributes.price.amount),
            });
            history.push(orderDetailsPath);
          })
          .catch(err => {
            this.setState({ submitting: false });
            logEvent('payment failed', {
              ['card type']: err?.payment_method?.card?.funding,
              ['total cart value']: getAmountAmplitude(this.props.listing.attributes.price.amount),
              ['payment method']:
                this.state.paymentType === 'direct-payment' ? 'stripe' : this.state.paymentType,
              ['error message']: err?.message,
              ['error code']: err?.code,
            });
            // Handle payment intent error
          });
      })
      .catch(error => {
        this.setState({ submitting: false });
        // Handle address update error
      });
  }

  handleOfferSubmit(params) {
    this.setState({ submitting: true });
    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      onUpdateAddress, // Assuming this is the method to update the address
    } = this.props;
    const { stripe, card, formValues, message, paymentMethod } = params;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;


    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};

    const billingDetails = {
      name,
      email: ensuredCurrentUser.attributes.email,
      ...addressMaybe,
    };

    // Update address first if needed
    const updateAddressPromise = this.state.hasAddressBeingTouched
      ? onUpdateAddress(currentUser.id.uuid, { address: this.state.address })
      : Promise.resolve();

    updateAddressPromise
      .then(() => {
        // After address update, proceed with setting up payment
        const requestPaymentParams = {
          pageData: this.state.pageData,
          speculatedTransaction,
          stripe: this.stripe,
          card,
          billingDetails,
          message,
          paymentIntent,
          selectedPaymentMethod: paymentMethod,
          saveAfterOnetimePayment: !!saveAfterOnetimePayment,
        };

        this.handlePaymentIntent(requestPaymentParams)
          .then(res => {
            const { orderId, messageSuccess, paymentMethodSaved } = res;
            this.setState({ submitting: false });
            const routes = routeConfiguration();
            const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
            const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
              id: orderId.uuid,
            });
            const initialValues = {
              initialMessageFailedToTransaction,
              savePaymentMethodFailed: !paymentMethodSaved,
            };
            initializeOrderPage(initialValues, routes, dispatch);
            clearData(STORAGE_KEY);
            history.push(orderDetailsPath);
            logEvent('Complete Offer');
            logEvent('order completed', {
              ['total cart value']: getAmountAmplitude(this.props.listing.attributes.price.amount),
              ['total shipping']: this.state.shippingPrice,
              ['shipping method']:
                this.state.shippingMethod === 'normalDelivery' ? 'standard' : 'express',
              ['payment method']:
                this.state.paymentType === 'direct-payment' ? 'stripe' : this.state.paymentType,
              ['order id']: res.id.uuid,
              ['order type']: getIsOffer(this.props.speculatedTransaction) ? 'offer' : 'purchase',
            });

            logPixelFacebook('Purchase', {
              values: getAmountAmplitude(this.props.listing.attributes.price.amount),
            });
          })
          .catch(err => {
            logEvent('payment failed', {
              ['card type']: err?.payment_method?.card?.funding,
              ['total cart value']: getAmountAmplitude(this.props.listing.attributes.price.amount),
              ['payment method']:
                this.state.paymentType === 'direct-payment' ? 'stripe' : this.state.paymentType,
              ['error message']: err?.message,
              ['error code']: err?.code,
            });
            this.setState({ submitting: false });
            // Handle error
          });
      })
      .catch(error => {
        this.setState({ submitting: false });
        // Handle address update error
      });
  }

  handlePaypalInit(orderId) {
    const { history, dispatch } = this.props;

    return this.initiatePaypalOrder(orderId).then(paypalTransactionState => {
      this.setState({ paypalTransactionState });
    });
  }

  handlePaypalPurchase(orderId) {
    const { history, dispatch, currentUser } = this.props;

    // Define a common function to handle the redirection to the order details page
    const redirectToOrderDetails = res => {
      const { id } = res;
      const routes = routeConfiguration();
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: id.uuid });
      const initialValues = {
        initialMessageFailedToTransaction: null,
        savePaymentMethodFailed: false,
      };

      initializeOrderPage(initialValues, routes, dispatch);
      clearData(STORAGE_KEY);
      history.push(orderDetailsPath);
    };

    const handleOrderProcess = res => {
      if (this.state.hasAddressBeingTouched) {
        this.props
          .onUpdateAddress(currentUser.id.uuid, { address: this.state.address })
          .then(() => redirectToOrderDetails(res))
          .catch(() => redirectToOrderDetails(res));
      } else {
        redirectToOrderDetails(res);
      }
    };

    this.transitionPaypalOrder(orderId).then(res => {
      handleOrderProcess(res);
    });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;
  }

  onSubmitAddress(values) {
    const {
      address,
      addressExt,
      addressInt,
      addressNeighborhood,
      addressZip,
      addressState,
      addressCity,
    } = values;

    const { onUpdateAddress, currentUser } = this.props;

    const profile = {
      address: {
        address_line: address,
        ext_number: addressExt,
        int_number: addressInt,
        neighborhood: addressNeighborhood,
        zip: addressZip,
        state: addressState,
        city: addressCity,
      },
    };

    this.setState({ zipIsUnavailable: false });

    const self = this;
    getIsZipCodeAvailable(addressZip)
      .then(response => {
        if (response.available) {
          onUpdateAddress(currentUser.id.uuid, profile)
            .then(() => {
              self.setState({ isAddressModalOpen: false, address: profile.address });
              // self.handlePublishListing(self.state.draftId);
            })
            .catch(() => {
              // do nothing
            });
        } else {
          this.setState({ zipIsUnavailable: true });
        }
      })
      .catch(e => {
        this.setState({ zipIsUnavailable: true });
      });
  }

  onSubmitPhoneNumber(values) {
    const { onUpdatePhoneNumber, currentUser } = this.props;

    const self = this;

    onUpdatePhoneNumber(currentUser.id.uuid, values.phoneNumber).then(res => {
      self.setState({ isPhoneNumberModalOpen: false, phoneNumber: values.phoneNumber });
    });
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, currentUser, listing } = this.props;
    const routes = routeConfiguration();
    const listingId = listing.id;
    const { message } = values;

    const receiver = listing.author.id.uuid;
    const sender = currentUser.id.uuid;

    onSendEnquiry(listingId.uuid, receiver, sender, message.trim())
      .then(txId => {
        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  setIsEnquiryModalOpen(value) {
    this.setState({ isEnquiryModalOpen: value });
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      setupIntentError,
      handleCardSetupError,
      sendEnquiryInProgress,
      sendEnquiryError,
      onManageDisableScrolling,
      onCreatePaypalOrder,
      onApprovePaypalOrder,
      onCheckAndValidateReferralCode,
      updateInProgress,
      updateError,
      validationInProgress,
      validationError,
      userReferralCode,
    } = this.props;
    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    let isOfferTransaction = bookingDates ? bookingDates.customPrice !== 1 : false;

    let isAManualPaymentTransition =
      existingTransaction.attributes.lastTransition === 'transition/transition-to-manual-payment' ||
      existingTransaction.attributes.lastTransition === 'transition/initiate-manual-payment' ||
      existingTransaction.attributes.lastTransition === 'transition/complete-manual-payment';

    let isManualPayment = isAManualPaymentTransition ? true : false;

    const titleId = isOfferTransaction
      ? isManualPayment
        ? 'CheckoutPage.offerTitle'
        : 'CheckoutPage.manualPaymentTitle'
      : 'CheckoutPage.title';
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: titleId }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          booking={txBooking}
          dateType={DATE_TYPE_DATE}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).attributes.card
        .fingerprint
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );

    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput = txIsRequested;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    const isOffer = speculatedTransactionMaybe
      ? speculatedTransactionMaybe.attributes.lineItems[0].quantity.d[0] !== 1
      : false;

    const shouldShippingOptionsBeingShown = speculatedTransactionMaybe ? true : false;

    const address = currentUser.attributes.profile.privateData.address
      ? currentUser.attributes.profile.privateData.address
      : null;

    const contact = currentUser.attributes.profile.privateData.phoneNumber
      ? currentUser.attributes.profile.privateData.phoneNumber
      : null;

    const amountToValidate = this.state.shouldFetchSpeculatedTransaction
      ? this.props.speculatedTransaction.attributes.payinTotal.amount / 100
      : this.props.transaction.attributes.payinTotal.amount / 100;

    const requiredAddressKeys = ['name', 'address_line', 'ext_number', 'neighborhood', 'zip', 'state', 'city'];
    
    const isAddressComplete = requiredAddressKeys.every(key => address?.[key]);
      
    const PrimaryButtonIsDisabled =
      this.state.submitting ||
      this.state.isShippingOptionBeingChanged ||
      this.state.isListingQAOptionsBeingChanged ||
      this.state.isMsiOptionsBeingChanged ||
      updateInProgress ||
      (this.state.hasAddressBeingTouched ? !this.state.isNewAddressCompleted : !isAddressComplete) ||
      (this.state.hasPhoneBeingTouched ? !this.state.isPhoneValid : false) ||
      this.state.submitDisabledStripe;

    const shouldButtonBeDisabled =
      this.state.submitting ||
      this.state.isShippingOptionBeingChanged ||
      this.state.isListingQAOptionsBeingChanged ||
      this.state.isMsiOptionsBeingChanged ||
      updateInProgress ||
      (this.state.hasAddressBeingTouched ? !this.state.isNewAddressCompleted : !isAddressComplete) ||
      (this.state.hasPhoneBeingTouched ? !this.state.isPhoneValid : false);

    const shippingSelectorContainer =
      shouldShippingOptionsBeingShown &&
      !(this.state.shouldHideComponents && this.state.paymentType === 'MSI') ? (
        <ShippingOptions
          onShippingPriceChange={this.onShippingPriceChange}
          onNewShippingMethodSelection={this.onNewShippingMethodSelection}
          listing={listing}
          provider={listing.author.id.uuid}
          currentUser={currentUser}
          onSubmitEnquiry={this.onSubmitEnquiry}
          sendEnquiryInProgress={sendEnquiryInProgress}
          sendEnquiryError={sendEnquiryError}
          onContactUser={this.onContactUser}
          isEnquiryModalOpen={this.state.isEnquiryModalOpen}
          setIsEnquiryModalOpen={this.setIsEnquiryModalOpen}
          originalShippingPrice={this.state.originalShippingPrice}
          onSubmit={() => void 0}
          isShippingOptionBeingChanged={this.state.isShippingOptionBeingChanged}
          setIsExpressDeliveryChosen={value => this.setState({isExpressDeliveryChosen: value})}
          isExpressDeliveryChosen={this.state.isExpressDeliveryChosen}
        ></ShippingOptions>
      ) : null;

    const authContainer = (
      <div
        className={
          this.state.shouldHideComponents && this.state.paymentType === 'MSI'
            ? css.hidden
            : css.addressForm
        }
      >
        <div className={css.containerHeading}>
          <ListingQualityAssurance
            className={
              ((this.state.shouldHideComponents && this.state.paymentType === 'MSI') ||
                this.state.paymentType !== 'Paypal') &&
              css.hidden
            }
            isListingQualityAssuranceSelected={this.state.ListingQualityAssurance}
            onListingQualityAssuranceChange={this.onListingQualityAssuranceChange}
            onSubmit={() => void 0}
            freeListingQualityAssurance={
              listing.attributes.price.amount * bookingDates.customPrice >= 1000000
            }
          />
        </div>
      </div>
    );

    const PaypalPaymentMethod = (this.props.speculatedTransaction || this.props.transaction) && (
      <div
        className={
          ((this.state.shouldHideComponents && this.state.paymentType === 'MSI') ||
            this.state.paymentType !== 'Paypal') &&
          css.hidden
        }
      >
        <PayPalButton
          amount={
            this.state.shouldFetchSpeculatedTransaction
              ? this.props.speculatedTransaction.attributes.payinTotal.amount / 100
              : this.props.transaction.attributes.payinTotal.amount / 100
          }
          handlePaypalInit={this.handlePaypalInit}
          handlePaypalPurchase={this.handlePaypalPurchase}
          className={css.paypalButton}
          showSpinner={
            (this.state.submitting ||
              this.state.isShippingOptionBeingChanged ||
              this.state.paymentType === 'Paypal') &&
            this.state.submitDisabledPaypal
          }
          onCreatePaypalOrder={onCreatePaypalOrder}
          onApprovePayment={onApprovePaypalOrder}
          isShippingOptionBeingChanged={this.state.isShippingOptionBeingChanged}
          onInitiateOrder={this.props.onInitiateOrder}
          shouldButtonBeDisabled={shouldButtonBeDisabled}
        />
      </div>
    );

    const addressContainer = (
      <div
        className={
          this.state.shouldHideComponents && this.state.paymentType === 'MSI'
            ? css.hidden
            : css.addressForm
        }
      >
        <div className={css.containerHeading}>
          <h1 className={css.subTitle}>Dirección de Envío</h1>
          {!this.state.isAddressModalOpen && (
            <div
              className={css.borderLine}
              onClick={() => this.setState({ isAddressModalOpen: true })}
            >
              {address && (
                <div className={css.addressContainerFlex}>
                  <div className={css.addressLines}>
                    <div className={css.lineDiv}>
                      {address?.name}
                    </div>
                    <div className={css.lineDiv}>
                      {address?.address_line} {address?.ext_number} {address?.int_number}
                    </div>
                    <div className={css.lineDiv}>
                      {address?.neighborhood}, {address?.zip}
                    </div>
                    <div className={css.lineDiv}>
                      {address?.city}, {address?.state}
                    </div>
                  </div>
                  <div className={css.addressIcon}>
                    <KeyboardArrowRightIcon />
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.isAddressModalOpen && (
            <SectionAddress
              isAddressModalOpen={this.state.isAddressModalOpen}
              onCloseAddressModal={() => this.setState({ isAddressModalOpen: false })}
              AddressUpdateInProgress={this.props.AddressUpdateInProgress}
              AddressUpdateError={this.props.AddressUpdateError}
              onToggleAddressModal={() => this.setState({ isAddressModalOpen: true })}
              onSubmit={values => this.onSubmitAddress(values)}
              manageDisableScrolling={onManageDisableScrolling}
              zipIsUnavailable={this.state.zipIsUnavailable}
              updateInProgress={updateInProgress}
              updateError={updateError}
              initialValues={{
                name: address?.name,
                address_line: address?.address_line,
                ext_number: address?.ext_number,
                int_number: address?.int_number,
                neighborhood: address?.neighborhood,
                zip: address?.zip,
                state: address?.state,
                city: address?.city,
              }}
              intl={intl}
              setNewAddressState={(field, value, isValid) => {
                const keys = ['name', 'address_line', 'ext_number', 'neighborhood', 'zip', 'state', 'city'];
                let addressState = this.state.address ? this.state.address : {};
                let fieldValidationStatus = this.state.fieldValidationStatus
                  ? this.state.fieldValidationStatus
                  : {};

                addressState[field] = value;
                fieldValidationStatus[field] = isValid;

                let isAddressCompleted = true;
                let isAddressValid = true;

                for (let key of keys) {
                  if (addressState[key] === undefined || addressState[key] === '') {
                    isAddressCompleted = false;
                  }

                  if (fieldValidationStatus[key] === false) {
                    isAddressValid = false;
                  }
                }

                this.setState({
                  address: addressState,
                  hasAddressBeingTouched: true,
                  fieldValidationStatus: fieldValidationStatus,
                });
              }}
              setAddressValid={value => {
                this.setState({ isNewAddressCompleted: value });
              }}
            />
          )}
        </div>
      </div>
    );
    const PhoneNumberContainer = (
      <div
        className={
          this.state.shouldHideComponents && this.state.paymentType === 'MSI'
            ? css.hidden
            : css.addressForm
        }
      >
        <div className={css.containerHeading}>
          <h1 className={css.subTitle}>Teléfono</h1>
          {/* <InlineTextButton name="ProfileSettingsPage" onClick={() => this.setState({isPhoneNumberModalOpen: true})}>Editar</InlineTextButton> */}

          {!this.state.isPhoneNumberModalOpen && (
            <div
              className={css.phoneNumberSavedContainer}
              onClick={() => this.setState({ isPhoneNumberModalOpen: true })}
            >
              <span>{contact}</span>
              <span>
                <KeyboardArrowRightIcon />
              </span>
            </div>
          )}
          {this.state.isPhoneNumberModalOpen && (
            <SectionPhoneNumber
              isPhoneNumberModalOpen={this.state.isPhoneNumberModalOpen}
              onClosePhoneNumberModal={() => this.setState({ isPhoneNumberModalOpen: false })}
              PhoneUpdateInProgress={this.props.PhoneUpdateInProgress}
              PhoneUpdateError={this.props.PhoneUpdateError}
              onTogglePhoneNumberModal={() => this.setState({ isPhoneNumberModalOpen: true })}
              onSubmit={values => this.onSubmitPhoneNumber(values)}
              updateError={this.state.updateError}
              setUpdateErrorValue={value => this.setState({ updateError: value })}
              initialValues={{ phoneNumber: contact }}
              setPhoneNumber={phoneNumberValue =>
                this.setState({ phoneNumber: phoneNumberValue, hasPhoneBeingTouched: true })
              }
            />
          )}
        </div>
      </div>
    );

    if (this.state.paymentType === 'Paypal' && amountToValidate <= 0) {
      this.setState({ paymentType: 'direct-payment' });
    }

    const paymentMethodSelector = !isOffer && amountToValidate > 0 && (
      <div>
        <h1 className={css.subTitle}>Método de pago</h1>
        <button
          className={`${css.paymentSelectorButton} ${this.state.paymentType === 'direct-payment' &&
            css.selected} `}
          onClick={() => {
            this.setState({ paymentType: 'direct-payment' });
            logEvent('payment type selected', {
              ['payment method']: 'stripe',
              ['total cart value']: listing.attributes.price.amount,
            });
          }}
        >
          <div className={css.IconPaypalPurchase}>Tarjeta de crédito o débito</div>
        </button>
        <button
          className={`${css.paymentSelectorButton} ${this.state.paymentType === 'Paypal' &&
            css.selected}`}
          onClick={() => {
            this.setState({ paymentType: 'Paypal' });
            logEvent('payment type selected', {
              ['payment method']: 'paypal',
              ['total cart value']: listing.attributes.price.amount,
            });
          }}
        >
          <IconPaypalPurchase className={css.IconPaypalPurchase} />
        </button>
      </div>
    );

    const handleMouseMove = event => {
      if (!address) {
        this.setState({
          showTooltip: true,
          tooltipX: event.clientX + 10, // Adjust as needed
          tooltipY: event.clientY + 10, // Adjust as needed
        });
      } else {
        this.setState({
          showTooltip: false,
        });
      }
    };

    const handleMouseLeave = () => {
      this.setState({
        showTooltip: false,
      });
    };

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
            <AvatarMedium user={currentAuthor} disableProfileLink />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>{title}</h1>
              <div className={css.listingTitleMobile}>
                <FormattedMessage
                  id="CheckoutPage.hostedBy"
                  values={{
                    listingName: (
                      <a href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${listing.id.uuid}`}>
                        {listingTitle}
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {breakdown}
              <ReferralComponent
                onCheckAndValidateReferralCode={code => {
                  if(currentListing.attributes.price.amount < 50000){
                    this.setState({ referredCodeValidation: 'under-500' });
                  }else {
                    onCheckAndValidateReferralCode(code, currentUser.id.uuid).then(
                    onCheckAndValidateReferralCodeRes => {
                      if (onCheckAndValidateReferralCodeRes) {
                        this.setState({ referredCode: code });
                      } else {
                        this.setState({ referredCode: null });
                      }
                      this.setState({ referredCodeValidation: 'does-not-exist' });
                    }
                  );
                  }
                }}
                validationInProgress={validationInProgress}
                validationError={validationError}
                referredCode={this.state.referredCode}
                referredCodeValidation={this.state.referredCodeValidation}
                isFirstBuy={this.state.isFirstBuy}
              />
            </div>
            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {shippingSelectorContainer}

              {authContainer}

              {addressContainer}

              {PhoneNumberContainer}

              {paymentMethodSelector}

              {showPaymentForm && this.state.paymentType === 'direct-payment' ? (
                <StripePaymentForm
                  className={css.paymentForm}
                  inProgress={
                    this.state.submitting ||
                    this.state.isShippingOptionBeingChanged ||
                    this.state.isListingQAOptionsBeingChanged
                  }
                  onSubmit={isOffer ? this.handleOfferSubmit : this.handleSubmit}
                  formId="CheckoutPagePaymentForm"
                  paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  handleCardPaymentError={handleCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  loadingData={!stripeCustomerFetched}
                  defaultPaymentMethod={
                    hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
                  }
                  isOffer={isOffer}
                  onShippingPriceChange={this.onShippingPriceChange}
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                  address={address}
                  contact={contact}
                  addPaymentMethodError={setupIntentError}
                  handleCardSetupError={handleCardSetupError}
                  onCreateShipmentQuotation={this.props.onCreateShipmentQuotation}
                  onNewShippingMethodSelection={this.onNewShippingMethodSelection}
                  //modal props
                  listing={listing}
                  provider={listing.author.id.uuid}
                  currentUser={currentUser.id.uuid}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  sendEnquiryError={sendEnquiryError}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={this.state.isEnquiryModalOpen}
                  setIsEnquiryModalOpen={this.setIsEnquiryModalOpen}
                  originalShippingPrice={this.state.originalShippingPrice}
                  shouldShippingOptionsBeingShown={shouldShippingOptionsBeingShown}
                  ref={this.stripeComponentRef}
                  onValueChange={this.handleValueChange}
                  submitDisabledStripe={this.state.submitDisabledStripe}
                  handleSubmitDisabledState={this.handleSubmitDisabledState}
                  speculatedTransaction={
                    this.state.shouldFetchSpeculatedTransaction
                      ? this.props.speculatedTransaction
                      : this.props.transaction
                  }
                  msiIntallmentsChange={this.msiIntallmentsChange}
                  chosenMsiInstallments={this.state.chosenMsiInstallments}
                  showMsiInstallments={
                    hasDefaultPaymentMethod
                      ? currentUser.stripeCustomer?.defaultPaymentMethod?.attributes?.card
                          ?.funding === 'credit'
                      : null
                  }
                  setStripeState={this.setStripeState}
                  isFacturationAddressNeeded={this.state.isFacturationAddressNeeded}
                  setIsFacturationAddressNeeded={() => {
                    this.setState({
                      isFacturationAddressNeeded: !this.state.isFacturationAddressNeeded,
                    });
                  }}
                />
              ) : null}

              <div
                className={
                  ((this.state.shouldHideComponents && this.state.paymentType === 'MSI') ||
                    this.state.paymentType !== 'Paypal') &&
                  css.hidden
                }
              >
                <PaypalPaymentForm
                  hasPaypalEmail={!!currentUser?.attributes?.profile?.privateData?.paypalEmail}
                  payeeEmail={currentUser?.attributes?.profile?.privateData?.paypalEmail}
                />
              </div>

              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
            </section>
          </div>
          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.avatarWrapper}>
              <AvatarMedium user={currentAuthor} disableProfileLink />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>
                <FormattedMessage
                  id="CheckoutPage.hostedBy"
                  values={{
                    listingName: (
                      <a href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${listing.id.uuid}`}>
                        {listingTitle}
                      </a>
                    ),
                  }}
                />
              </h2>
              <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
            </div>
            {speculateTransactionErrorMessage}
            {breakdown}
            <ReferralComponent
              onCheckAndValidateReferralCode={code => {
                if(currentListing.attributes.price.amount < 50000){
                  this.setState({ referredCodeValidation: 'under-500' });
                }
                else{
                  onCheckAndValidateReferralCode(code, currentUser.id.uuid).then(
                  onCheckAndValidateReferralCodeRes => {
                    if (onCheckAndValidateReferralCodeRes) {
                      this.setState({ referredCode: code });
                    } else {
                      this.setState({ referredCode: null });
                    }
                    this.setState({ referredCodeValidation: 'does-not-exist' });
                  }
                );
                }
              }}
              validationInProgress={validationInProgress}
              validationError={validationError}
              referredCode={this.state.referredCode}
              referredCodeValidation={this.state.referredCodeValidation}
              isFirstBuy={this.state.isFirstBuy}
            />

            <PrimaryButton
              onClick={this.handleChildSubmit}
              inProgress={
                this.state.submitting ||
                this.state.isShippingOptionBeingChanged ||
                this.state.isListingQAOptionsBeingChanged ||
                this.state.isMsiOptionsBeingChanged ||
                updateInProgress
              }
              disabled={PrimaryButtonIsDisabled}
              className={
                (this.state.shouldHideComponents && this.state.paymentType === 'MSI') ||
                this.state.paymentType === 'Paypal'
                  ? css.hidden
                  : css.submitButton
              }
            >
              {true
                ? isOffer
                  ? 'Hacer Oferta'
                  : 'Comprar'
                : isOffer
                ? 'Confirmar la oferta'
                : 'Confirmar la compra'}
            </PrimaryButton>

            {PaypalPaymentMethod}
          </div>

          <div className={css.detailsContainerMobile}>
            <PrimaryButton
              onClick={this.handleChildSubmit}
              inProgress={
                this.state.submitting ||
                this.state.isShippingOptionBeingChanged ||
                this.state.isListingQAOptionsBeingChanged ||
                this.state.isMsiOptionsBeingChanged
              }
              disabled={PrimaryButtonIsDisabled}
              className={
                (this.state.shouldHideComponents && this.state.paymentType === 'MSI') ||
                this.state.paymentType === 'Paypal'
                  ? css.hidden
                  : css.submitButton
              }
            >
              {true
                ? isOffer
                  ? 'Hacer Oferta'
                  : 'Comprar'
                : isOffer
                ? 'Confirmar la oferta'
                : 'Confirmar la compra'}
            </PrimaryButton>

            {PaypalPaymentMethod}
          </div>
          {this.state.showWhatsAppChat && (
          <WhatsAppChat
            phoneNumber="5218123465519" 
            message="Tengo una pregunta" 
            componentTextShown={true}
            onClose={this.handleWhatsAppClose} // Pass the close handler
            showText={false}
          />
        )}

        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
  setupIntentError: null,
  handleCardSetupError: null,
  sales: 0,
  sendEnquiryError: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  setupIntentError: object,
  handleCardSetupError: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  userTransactions: func.isRequired,
  onAddAbandonedShoppingCart: func.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onHandleCardSetup: func.isRequired,
  onCreateSetupIntent: func.isRequired,
  sales: number,
  onSendEnquiry: func.isRequired,

  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  sendEnquiryError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  sendEnquiryInProgress: bool.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    sales,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateInProgress,
    updateError,
    validationInProgress,
    validationError,
    userReferralCode,
  } = state.CheckoutPage;
  const { currentUser } = state.user;

  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    handleCardSetupError,
    setupIntentInProgress,
    setupIntentError,
    setupIntent,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    setupIntentInProgress,
    setupIntentError,
    setupIntent,
    handleCardSetupError,
    sales,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateInProgress,
    updateError,
    validationInProgress,
    validationError,
    userReferralCode,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, transactionId, isOffer, paymentMethod) => {
    dispatch(speculateTransaction(params, transactionId, isOffer, paymentMethod));
  },
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId, isOffer, paymentMethod) =>
    dispatch(initiateOrder(params, transactionId, isOffer, paymentMethod)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: (params, isOffer) => dispatch(confirmPayment(params, isOffer)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onUpdateUserPrivateData: (id, stripeCustomerId) =>
    dispatch(updateUserPrivateData(id, stripeCustomerId)),
  userTransactions: userId => dispatch(queryUserTransactions(userId)),
  onAddAbandonedShoppingCart: (userId, listingId) =>
    dispatch(addAbandonedShoppingCart(userId, listingId)),
  onUpdateSpeculateTransaction: params => dispatch(updateSpeculateTransaction(params)),
  onCreateShipmentQuotation: params => dispatch(createShipmentQuotation(params)),
  onSendEnquiry: (listingId, providerId, customerId, message) =>
    dispatch(sendEnquiry(listingId, providerId, customerId, message)),
  onMessageTransactionValidation: (listingId, providerId, customerId) =>
    dispatch(messageTransactionValidation(listingId, providerId, customerId)),
  onValidatePaypalAmount: (orderId, transactionId) =>
    dispatch(validatePaypalAmount(orderId, transactionId)),
  onUpdateAddress: (id, address) => dispatch(updateAddress(id, address)),
  onUpdatePhoneNumber: (id, phoneNumber) => dispatch(updatePhoneNumber(id, phoneNumber)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreatePaypalOrder: amount => dispatch(createPaypalOrder(amount)),
  onApprovePaypalOrder: (paymentId, payerId) => dispatch(approvePaypalOrder(paymentId, payerId)),
  onCheckAndValidateReferralCode: (code, currenUserId) =>
    dispatch(checkAndValidateReferralCode(code, currenUserId)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

// ReactDOM.render(<CheckoutPageComponent />, document.querySelector('#root'));
export default CheckoutPage;
