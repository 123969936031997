import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
// import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getNotifications } from '../../util/api';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import NotificationItem from './NotificationItem';
import css from './NotificationsPage.css';
import { loadData, queryFullUser } from './NotificationsPage.duck';

const notificationsPerPage = 15;
export class NotificationsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      olderNotifications: [],
      queryInProgress: false,
      loadMore: false,
      lastResult: null,
    };
  }

  componentDidMount() {
    const { currentUser, getNotifications } = this.props;

    if (!!currentUser && !!currentUser.id) {
      getNotifications(currentUser.id.uuid).then(() => {
        this.setState({ loadMore: true });
      });
    }

    var options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
    if (this.loadingRef) {
      this.observer.observe(this.loadingRef);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.loadingRef) {
      this.observer.observe(this.loadingRef);
    }
    const { currentUser, getNotifications } = this.props;

    if (currentUser !== prevProps.currentUser && !!currentUser.id) {
      getNotifications(currentUser.id.uuid).then(() => {
        this.setState({ loadMore: true });
      });
    }
  }

  handleObserver(entities, observer) {
    if (entities[0].isIntersecting) {
      this.loadMoreNotifications();
    }
  }

  loadMoreNotifications = () => {
    if (this.props.queryUserInProgress || this.state.queryInProgress || !this.state.loadMore)
      return;

    const { olderNotifications, lastResult } = this.state;
    this.setState({ queryInProgress: true });
    getNotifications({ last: lastResult }).then(res => {
      if (!res.notifications) {
        return this.setState({ loadMore: false, queryInProgress: false });
      }

      const notifications = Object.values(res.notifications);
      if (notifications.length < notificationsPerPage) {
        this.setState({ loadMore: false });
      }

      const lastResult = Object.keys(res.notifications)[Object.keys(res.notifications).length - 1];
      this.setState({
        olderNotifications: [...olderNotifications, ...notifications],
        queryInProgress: false,
        lastResult,
      });
    });
  };

  render() {
    const { loadMoreNotifications } = this;
    const { olderNotifications, loadMore, queryInProgress } = this.state;
    const { scrollingDisabled, notifications, queryUserInProgress, history } = this.props;
    const myNotifications = [...notifications, ...olderNotifications].sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      return bDate - aDate;
    });

    const schemaTitle = 'Notificaciones';

    let content = null;

    if (queryUserInProgress) {
      content = (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress className={css.loader} color={'inherit'} />
        </Grid>
      );
    } else if (notifications.length) {
      content = (
        <Grid container spacing={2}>
          {myNotifications.map((n, k) => {
            return (n.type !== 'follow' && n.listing.slug ) ?  (
              <Grid item xs={12} key={k} className={css.notificationItem}>
                <NotificationItem notification={n} history={history} />
              </Grid>
            ) : null;
          })}
          <Grid
            item
            xs={12}
            style={{ textAlign: 'center' }}
            ref={loadingRef => (this.loadingRef = loadingRef)}
          >
            {loadMore && !queryInProgress && (
              <button
                type="button"
                onClick={e => {
                  e.preventDefault();
                  loadMoreNotifications();
                }}
              >
                Ver más
              </button>
            )}
            {queryInProgress && <CircularProgress className={css.loader} color={'inherit'} />}
          </Grid>
        </Grid>
      );
    } else {
      content = (
        <Grid item xs={12}>
          <h3>Todavía no tienes notificaciones.</h3>
        </Grid>
      );
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'NotificationsPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="NotificationsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <h1 className={css.title}>Mis Notificaciones</h1>
              <div>{content}</div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NotificationsPageComponent.defaultProps = {
  currentUser: null,
  notifications: [],
  queryListingsInProgress: null,
};

const { number, shape } = PropTypes;

NotificationsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  queryUserInProgress: PropTypes.bool,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { queryUserInProgress, notifications } = state.NotificationsPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    queryUserInProgress,
    currentUser,
    notifications,
  };
};

const mapDispatchToProps = dispatch => ({
  getNotifications: user => dispatch(queryFullUser(user)),
});

const NotificationsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(NotificationsPageComponent);

NotificationsPage.loadData = () => {
  return loadData();
};

export default NotificationsPage;
